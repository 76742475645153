import { api } from '@source/services/api';
import { useQuery } from '@tanstack/react-query';
import QrCode from 'react-qr-code';
import { useState } from 'react';
import { parseCookies, setCookie } from 'nookies';
import { useRouter } from 'next/router';
import { useTheme } from 'styled-components';
import { IDefaultTheme } from '@source/styles/themes';
import { useGetBranchLink } from '@source/recoil/hooks';
import * as S from './styles';

const pagesToExclude = [
  'api',
  'checkout',
  'dashboard',
  'share',
  'ranks',
  'legal',
  'login',
  'logout',
  'confirm-subscription',
  'server-sitemap',
  'forgotPassword',
  'redirect',
  'register',
  'garmin',
  'polar',
  'strava',
  'wahoo',
  'workshop-bike-connection'
];

type QrCodeAppDownloadProviderProps = { children: React.ReactNode };

const QrCodeAppDownloadProvider: React.FC<QrCodeAppDownloadProviderProps> = ({
  children
}): JSX.Element => {
  const router = useRouter();
  const theme = useTheme() as IDefaultTheme;
  const { branchLink: challengeBranchLink } = useGetBranchLink();

  const isPageAvailable = !pagesToExclude.some(page =>
    router.pathname.includes(page)
  );

  const cookies = parseCookies();
  const isWidgetHiddenCookie =
    cookies['riderize@is-qrcode-app-download-hidden'] === 'true';

  const [isWidgetHidden, setIsWidgetHidden] = useState(isWidgetHiddenCookie);

  const { data: { data: { deviceType = '' } = {} } = {} } = useQuery({
    queryKey: ['getDeviceType'],
    queryFn: async () => {
      return await api.get('/get-device-type');
    }
  });

  const hideWidget = (hide: boolean) => {
    setIsWidgetHidden(hide);

    setCookie(undefined, 'riderize@is-qrcode-app-download-hidden', 'true', {
      maxAge: 60 * 60 * 24 * 300, // 300 days
      path: '/'
    });
  };

  return (
    <>
      {children}

      {deviceType &&
      (deviceType === 'desktop' || deviceType === 'tablet') &&
      isPageAvailable &&
      !isWidgetHidden ? (
        <S.ButtonOverlay>
          <S.CloseButton onClick={() => hideWidget(true)} />
          <QrCode
            size={125}
            value={challengeBranchLink}
            fgColor={theme.colors.blue}
          />
          <p>baixe o app</p>
        </S.ButtonOverlay>
      ) : null}
    </>
  );
};

export default QrCodeAppDownloadProvider;
