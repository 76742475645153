import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import nookies from 'nookies';

const cookies = nookies.get();

const token = cookies['riderize@accessToken']
  ? cookies['riderize@accessToken']
  : null;

const refreshToken = cookies['riderize@refreshToken']
  ? cookies['riderize@refreshToken']
  : null;

export const apolloClient = (
  definedToken?: string,
  definedRefreshToken?: string
) =>
  new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link: createHttpLink({
      uri: `${process.env.NEXT_PUBLIC_API_URL}/graphql`,
      credentials: 'same-origin',
      headers: {
        timezone: 'America/Sao_Paulo',
        Languagecode: 'pt-BR',
        latitude: '-27.6343411',
        longitude: '-52.2768839',
        Authorization: `Bearer ${definedToken || token}`,
        'x-refresh-token': `Bearer ${definedRefreshToken || refreshToken}`
      }
    }),
    cache: new InMemoryCache()
  });

export default apolloClient();

// Ao tentar importar estas funções, use export default para usar tokens do
// client-side, e export regular para invocar a função e passar tokens por server-side
